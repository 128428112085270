import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from './UserContext';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Button } from './ui/button';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  Trophy, Trash2, LogOut, Clock, CreditCard, User, Calendar, 
  ChevronRight, AlertCircle, BookOpen, Zap, Activity, TrendingUp 
} from 'lucide-react';

// Helper function to format dates
const formatDate = (dateString) => {
  if (!dateString) return 'Date not available';
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};

const Account = () => {
  const { user, logout, deleteExam, updateExamScore, removeInProgressAttempt } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleRetakeExam = async (exam) => {
    try {
      const newAttemptId = Date.now().toString();
      const newAttempt = {
        id: newAttemptId,
        score: null,
        date: new Date().toISOString(),
        answers: [],
        completed: false
      };

      const updatedExam = {
        ...exam,
        attempts: [...(exam.attempts || []), newAttempt]
      };

      await updateExamScore(exam.name, newAttempt);
      localStorage.setItem('currentExam', JSON.stringify(updatedExam));
      navigate('/practice-test');
    } catch (error) {
      console.error("Error starting exam:", error);
      setError("Failed to start exam. Please try again.");
    }
  };

  const handleDeleteExam = async (examToDelete) => {
    try {
      await deleteExam(examToDelete);
      setReloadTrigger(prev => prev + 1);
    } catch (error) {
      setError("Failed to delete exam. Please try again.");
    }
  };

  const handleViewAttempt = (exam, attempt) => {
    navigate(`/exam-result/${encodeURIComponent(exam.name)}/${attempt.id}`);
  };

  const sortedExams = user && user.savedExams ? [...user.savedExams].sort((a, b) => new Date(b.date) - new Date(a.date)) : [];

  // Calculate statistics
  const totalExamsTaken = sortedExams.reduce((total, exam) => total + (exam.attempts?.length || 0), 0);
  const averageScore = sortedExams.length > 0
    ? sortedExams.reduce((sum, exam) => sum + (exam.lastScore || 0), 0) / sortedExams.length
    : 0;

  // Mock data for recent activity and recommendations
  const recentActivity = [
    { type: 'exam_completed', examName: 'AZ-900', score: 20, date: new Date().toISOString() },
    // Add more activity items as needed
  ];

  const recommendations = [
    { type: 'exam', name: 'AZ-900', reason: 'Retake to improve your score' },
    { type: 'topic', name: 'Azure Virtual Machines', reason: 'Strengthen your knowledge' },
    { type: 'resource', name: 'Azure Fundamentals', reason: 'Supplement your current studies' },
  ];

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 p-4 flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Card className="w-full max-w-md bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
            <CardContent className="p-6">
              <div className="flex items-center space-x-2 text-red-600 mb-4">
                <AlertCircle className="h-5 w-5" />
                <p>{error}</p>
              </div>
              <Button onClick={() => navigate('/account')} className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300">
                Back to Account
              </Button>
            </CardContent>
          </Card>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 p-8">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex justify-between items-center mb-8"
        >
          <div>
            <h1 className="text-4xl font-bold text-gray-800">Welcome, {user ? user.name : ''}!</h1>
            <p className="text-xl text-gray-600 mt-2">Your learning journey awaits</p>
          </div>
          <Button onClick={handleLogout} className="bg-gradient-to-r from-red-500 to-pink-600 hover:from-red-600 hover:to-pink-700 text-white transition-all duration-300">
            <LogOut className="mr-2 h-4 w-4" /> Logout
          </Button>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="lg:col-span-2"
          >
            <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl overflow-hidden mb-8">
              <CardHeader className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-6">
                <CardTitle className="text-2xl font-semibold flex items-center">
                  <User className="mr-2 h-6 w-6" /> Your Plan
                </CardTitle>
              </CardHeader>
              <CardContent className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center space-x-2">
                    <CreditCard className="h-5 w-5 text-purple-500" />
                    <span className="text-lg text-gray-700">Current Plan:</span>
                  </div>
                  <span className="text-lg font-semibold text-purple-600 capitalize">{user ? user.plan : 'Loading...'}</span>
                </div>
                {user && user.maxExams != null && user.remainingExams != null && (
                  <div className="mb-6">
                    <div className="flex justify-between mb-2">
                      <span className="text-gray-700">Available Exams:</span>
                      <span className="text-purple-600 font-semibold">{user.remainingExams} / {user.maxExams}</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-3">
                      <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: `${(user.remainingExams / user.maxExams) * 100}%` }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                        className="bg-gradient-to-r from-blue-400 to-purple-500 h-3 rounded-full"
                      ></motion.div>
                    </div>
                  </div>
                )}
                <div className="flex justify-end">
                  <Button onClick={() => navigate('/pricing')} className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300">
                    <Zap className="mr-2 h-4 w-4" /> Upgrade Plan
                  </Button>
                </div>
              </CardContent>
            </Card>

            <h2 className="text-2xl font-bold text-gray-800 mb-4">Your Practice Tests</h2>
            {sortedExams.length === 0 ? (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
                  <CardContent className="p-6 text-center">
                    <BookOpen className="h-12 w-12 text-purple-500 mx-auto mb-4" />
                    <p className="text-gray-600">No saved exams yet. Start your learning journey by generating a new exam!</p>
                    <Button onClick={() => navigate('/')} className="mt-4 bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300">
                      Create New Exam
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            ) : (
              <div className="space-y-4">
                {sortedExams.map((exam, index) => (
                  <motion.div
                    key={`${exam.name}-${index}-${reloadTrigger}`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <Card className="bg-white/90 backdrop-blur-md shadow-md rounded-xl overflow-hidden hover:shadow-lg transition-all duration-300">
                      <CardContent className="p-4">
                        <div className="flex justify-between items-center">
                          <div>
                            <h3 className="text-xl font-semibold text-gray-800">{exam.name}</h3>
                            <p className="text-sm text-gray-500">Created: {formatDate(exam.date)}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            {exam.lastScore !== null && exam.lastScore !== undefined && (
                              <div className="flex items-center bg-yellow-100 text-yellow-800 px-3 py-1 rounded-full">
                                <Trophy className="h-5 w-5 mr-2" />
                                <span className="font-semibold">{exam.lastScore.toFixed(0)}%</span>
                              </div>
                            )}
                            <Button onClick={() => handleRetakeExam(exam)} className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white text-sm transition-all duration-300">
                              Retake
                            </Button>
                            <Button onClick={() => handleDeleteExam(exam)} className="bg-gradient-to-r from-red-500 to-pink-600 hover:from-red-600 hover:to-pink-700 text-white p-2 transition-all duration-300">
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </div>
                        {exam.attempts && exam.attempts.length > 0 && (
                          <div className="mt-4">
                            <h4 className="text-sm font-semibold text-gray-700 mb-2">Attempts:</h4>
                            <div className="flex flex-wrap gap-2">
                              {exam.attempts.map((attempt, attemptIndex) => (
                                <Button 
                                  key={attemptIndex} 
                                  onClick={() => handleViewAttempt(exam, attempt)}
                                  className="bg-gradient-to-r from-gray-200 to-gray-300 hover:from-gray-300 hover:to-gray-400 text-gray-800 text-xs py-1 px-2 transition-all duration-300"
                                >
                                  <Clock className="mr-1 h-3 w-3" />
                                  {attempt.score !== null && attempt.score !== undefined ? `${attempt.score.toFixed(0)}%` : 'In Progress'}
                                </Button>
                              ))}
                            </div>
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  </motion.div>
                ))}
              </div>
            )}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="lg:col-span-1"
          >
            <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl sticky top-8 mb-8">
              <CardHeader className="bg-gradient-to-r from-blue-400 to-purple-500 text-white p-6">
                <CardTitle className="text-xl font-semibold flex items-center">
                  <Zap className="mr-2 h-5 w-5" /> Quick Stats
                </CardTitle>
              </CardHeader>
              <CardContent className="p-6">
                <div className="space-y-4">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.5 }}
                  >
                    <p className="text-gray-600">Total Exams Taken</p>
                    <p className="text-2xl font-bold text-gray-800">{totalExamsTaken}</p>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                  >
                    <p className="text-gray-600">Average Score</p>
<p className="text-2xl font-bold text-gray-800">{averageScore.toFixed(1)}%</p>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.7 }}
                  >
                    <p className="text-gray-600">Exams Created</p>
                    <p className="text-2xl font-bold text-gray-800">{sortedExams.length}</p>
                  </motion.div>
                </div>
              </CardContent>
            </Card>
            <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl sticky top-8">
              <CardHeader className="bg-gradient-to-r from-green-400 to-blue-500 text-white p-6">
                <CardTitle className="text-xl font-semibold flex items-center">
                  <Activity className="mr-2 h-6 w-6" /> Learning Progress
                </CardTitle>
              </CardHeader>
              <CardContent className="p-6">
                <div className="mb-6">
                  <h3 className="text-xl font-semibold text-gray-800 mb-4">Recent Activity</h3>
                  <ul className="space-y-3">
                    {recentActivity.map((activity, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        className="flex items-center text-gray-600 bg-gray-50 rounded-lg p-3"
                      >
                        {activity.type === 'exam_completed' && <Trophy className="h-5 w-5 mr-2 text-yellow-500" />}
                        {activity.type === 'exam_created' && <BookOpen className="h-5 w-5 mr-2 text-blue-500" />}
                        {activity.type === 'achievement' && <Zap className="h-5 w-5 mr-2 text-purple-500" />}
                        <span>
                          {activity.type === 'exam_completed' && `Completed ${activity.examName} with score ${activity.score}%`}
                          {activity.type === 'exam_created' && `Created new exam: ${activity.examName}`}
                          {activity.type === 'achievement' && `Earned achievement: ${activity.name}`}
                          <span className="text-gray-400 ml-2 text-sm">{formatDate(activity.date)}</span>
                        </span>
                      </motion.li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-800 mb-4">Personalized Recommendations</h3>
                  <ul className="space-y-3">
                    {recommendations.map((rec, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        className="bg-gray-50 rounded-lg p-3 hover:shadow-md transition-all duration-300"
                      >
                        <div className="flex items-center text-gray-800 font-semibold">
                          {rec.type === 'exam' && <BookOpen className="h-5 w-5 mr-2 text-blue-500" />}
                          {rec.type === 'topic' && <TrendingUp className="h-5 w-5 mr-2 text-green-500" />}
                          {rec.type === 'resource' && <BookOpen className="h-5 w-5 mr-2 text-orange-500" />}
                          {rec.name}
                        </div>
                        <p className="text-gray-600 text-sm mt-1">{rec.reason}</p>
                      </motion.li>
                    ))}
                  </ul>
                </div>
              </CardContent>
            </Card>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Account;