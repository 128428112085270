import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { UserContext } from './UserContext';
import { motion } from 'framer-motion';
import { AlertCircle } from 'lucide-react';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const navigate = useNavigate();
  const { signup } = useContext(UserContext);

  const getUnmetPasswordCriteria = (password) => {
    const criteria = [];
    if (password.length < 8) criteria.push('At least 8 characters');
    if (!/[A-Z]/.test(password)) criteria.push('One uppercase letter');
    if (!/[a-z]/.test(password)) criteria.push('One lowercase letter');
    if (!/[0-9]/.test(password)) criteria.push('One number');
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) criteria.push('One special character');
    return criteria;
  };

  const unmetCriteria = getUnmetPasswordCriteria(password);
  const isPasswordStrong = unmetCriteria.length === 0;

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!isPasswordStrong) {
      setError("Please ensure your password meets all the strength requirements.");
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      setLoading(false);
      return;
    }

    try {
      await signup(email, password, name);
      setVerificationSent(true);
    } catch (error) {
      console.error('Sign Up Error:', error);
      setError(`Sign up failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (verificationSent) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex flex-col items-center justify-center p-4">
        <Card className="w-full max-w-lg bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
          <CardContent className="p-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Verification Email Sent</h2>
            <p className="text-gray-600 mb-4">
              A verification email has been sent to {email}. Please check your inbox and click on the verification link to complete your registration.
            </p>
            <Button onClick={() => navigate('/login')} className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300">
              Go to Login
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex flex-col items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-lg"
      >
        <h1 className="text-4xl font-bold text-gray-800 mb-8 text-center">Create an Account</h1>
        <Card className="w-full bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
          <CardContent className="p-8">
            <form onSubmit={handleSignUp} className="space-y-6">
              <div>
                <Input 
                  type="text"
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full p-3 rounded-full border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
                  required
                />
              </div>
              <div>
                <Input 
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-3 rounded-full border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
                  required
                />
              </div>
              <div>
                <Input 
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() => setPasswordFocused(true)}
                  className="w-full p-3 rounded-full border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
                  required
                />
              </div>
              {passwordFocused && unmetCriteria.length > 0 && (
                <div className="space-y-2">
                  <p className="text-sm font-semibold text-gray-700">Password must also contain:</p>
                  {unmetCriteria.map((criterion) => (
                    <div key={criterion} className="flex items-center space-x-2">
                      <AlertCircle className="text-red-500" size={16} />
                      <span className="text-sm text-red-500">{criterion}</span>
                    </div>
                  ))}
                </div>
              )}
              <div>
                <Input 
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordTouched(true);
                  }}
                  className="w-full p-3 rounded-full border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
                  required
                />
              </div>
              {confirmPasswordTouched && password !== confirmPassword && (
                <p className="text-red-500 text-sm">Passwords don't match</p>
              )}
              {error && <p className="text-red-500 text-sm">{error}</p>}
              <Button type="submit" className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-4 px-6 rounded-full transition-all duration-300 text-lg" disabled={loading || !isPasswordStrong || password !== confirmPassword}>
                {loading ? 'Signing Up...' : 'Sign Up'}
              </Button>
            </form>
            <div className="mt-6 text-center">
              <span className="text-sm text-gray-600">Already have an account? </span>
              <button 
                onClick={() => navigate('/login')}
                className="text-sm text-blue-600 hover:underline font-semibold"
              >
                Log In
              </button>
            </div>
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
};

export default SignUp;