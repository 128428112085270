import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Checkbox } from './ui/checkbox';
import { UserContext } from './UserContext';
import { motion } from 'framer-motion';
import { AlertCircle, CheckCircle } from 'lucide-react';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [resetCooldown, setResetCooldown] = useState(false);
  const cooldownTime = 60;
  const { loginWithEmailPassword, loginWithGoogle, forgotPassword } = useContext(UserContext);

  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state.message);
    }
  }, [location]);

  const handleEmailPasswordAuth = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await loginWithEmailPassword(email, password);
      navigate('/');
    } catch (error) {
      console.error('Email/Password Auth Error:', error);
      setError(`Wrong Email or Password`);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    setLoading(true);
    try {
      const decoded = jwtDecode(credentialResponse.credential);
      console.log(decoded);
      
      await loginWithGoogle();
      navigate('/');
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      setError('Google login failed. Please try again. Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLoginError = (error) => {
    console.error('Google Sign-In Error:', error);
    setError('Google login failed. Please try again. Error: ' + JSON.stringify(error));
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setResetEmailSent(false);

    if (resetCooldown) {
      setError(`Please wait before requesting another reset email.`);
      setLoading(false);
      return;
    }

    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      
      if (signInMethods.length > 0) {
        await forgotPassword(email);
        setResetEmailSent(true);
        setResetCooldown(true);
        setTimeout(() => setResetCooldown(false), cooldownTime * 1000);
      } else {
        setResetEmailSent(true);
      }
    } catch (error) {
      console.error('Forgot Password Error:', error);
      setError(`An error occurred. Please try again later.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex flex-col items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <Card className="w-full bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
          <CardContent className="p-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">
              {isForgotPassword ? 'Reset Password' : 'Login'}
            </h2>
            {message && (
              <div className="mb-4 p-4 bg-green-100 border-l-4 border-green-500 text-green-700 flex items-center">
                <CheckCircle className="mr-2" />
                <p>{message}</p>
              </div>
            )}
            {isForgotPassword ? (
              // Reset Password Form
              <>
                {resetEmailSent && (
                  <div className="mb-4 p-4 bg-green-100 border-l-4 border-green-500 text-green-700 flex items-center">
                    <CheckCircle className="mr-2" />
                    <p>Password reset email sent. Please check your inbox.</p>
                  </div>
                )}
                <form onSubmit={handleForgotPassword} className="space-y-6">
                  <Input 
                    type="email" 
                    placeholder="Email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-3 rounded-full border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
                    required
                  />
                  {error && <p className="text-red-500 text-sm">{error}</p>}
                  <Button 
                    type="submit" 
                    className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300" 
                    disabled={loading || resetCooldown}
                  >
                    {loading ? 'Sending...' : resetCooldown ? `Wait ${cooldownTime}s` : 'Reset Password'}
                  </Button>
                </form>
                <div className="mt-4 text-center">
                  <button 
                    onClick={() => setIsForgotPassword(false)}
                    className="text-sm text-blue-600 hover:underline"
                  >
                    Back to Login
                  </button>
                </div>
              </>
            ) : (
              // Login Form
              <>
                <form onSubmit={handleEmailPasswordAuth} className="space-y-6">
                  <Input 
                    type="email" 
                    placeholder="Email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-3 rounded-full border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
                  />
                  <Input 
                    type="password" 
                    placeholder="Password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-3 rounded-full border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
                  />
                  {error && <p className="text-red-500 text-sm">{error}</p>}
                  <div className="flex items-center justify-between">
                    <Checkbox
                      id="remember"
                      label="Remember me"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <button 
                      type="button"
                      onClick={() => setIsForgotPassword(true)}
                      className="text-sm text-blue-600 hover:underline"
                    >
                      Forgot password?
                    </button>
                  </div>
                  <Button type="submit" className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-4 px-6 rounded-full transition-all duration-300 text-lg" disabled={loading}>
                    {loading ? 'Logging in...' : 'Log In'}
                  </Button>
                </form>
                <div className="mt-6 text-center">
                  <span className="text-sm text-gray-600">Don't have an account? </span>
                  <button 
                    onClick={() => navigate('/signup')}
                    className="text-sm text-blue-600 hover:underline font-semibold"
                  >
                    Sign Up
                  </button>
                </div>
                <div className="mt-8 relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-4 bg-white text-gray-500">Or continue with</span>
                  </div>
                </div>
                <div className="mt-8 grid grid-cols-1 gap-4">
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                    </div>
                  ) : (
                    <GoogleLogin
                      onSuccess={handleGoogleLoginSuccess}
                      onError={handleGoogleLoginError}
                      useOneTap
                      flow="implicit"
                      auto_select
                      ux_mode="popup"
                      theme="filled_blue"
                      shape="pill"
                      size="large"
                      text="continue_with"
                      logo_alignment="center"
                      width="100%"
                    />
                  )}
                </div>
              </>
            )}
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
};

export default Login;