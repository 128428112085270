import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { UserContext } from './UserContext';
import { motion } from 'framer-motion';
import { ExternalLink, AlertCircle, CheckSquare, XSquare } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction
} from './AlertDialog';  // Make sure this path is correct

const Switch = ({ id, checked, onCheckedChange }) => {
  return (
    <label htmlFor={id} className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          id={id}
          className="sr-only"
          checked={checked}
          onChange={onCheckedChange}
        />
        <div className={`block w-14 h-8 rounded-full ${checked ? 'bg-blue-500' : 'bg-gray-300'}`}></div>
        <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${checked ? 'transform translate-x-6' : ''}`}></div>
      </div>
    </label>
  );
};

const PracticeTestPlayground = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateExamScore } = useContext(UserContext);
  const [exam, setExam] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [practiceMode, setPracticeMode] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [error, setError] = useState('');
  const [showExitDialog, setShowExitDialog] = useState(false);

  const parseAnswers = (answers) => {
    return answers.map(answer => {
      try {
        return JSON.parse(answer);
      } catch (e) {
        console.error("Error parsing answer:", e);
        return answer;
      }
    });
  };

  useEffect(() => {
    const storedExam = localStorage.getItem('currentExam');
    if (storedExam) {
      const parsedExam = JSON.parse(storedExam);
      if (parsedExam.attempts && parsedExam.attempts.length > 0) {
        parsedExam.attempts = parsedExam.attempts.map(attempt => ({
          ...attempt,
          answers: parseAnswers(attempt.answers)
        }));
      }
      setExam(parsedExam);
      setStartTime(Date.now());
    } else if (location.state && location.state.examId) {
      navigate('/', { state: { message: "Exam not found. Please generate a new exam." } });
    } else {
      navigate('/', { state: { message: "No active exam found. Please generate a new exam." } });
    }
  }, [navigate, location.state]);

  const handleAnswer = (answer) => {
    setUserAnswers(prev => {
      const currentAnswers = prev[currentQuestionIndex] || [];
      const isMultipleChoice = exam.content.questions[currentQuestionIndex].answers.length > 1;
      
      let newAnswers;
      if (isMultipleChoice) {
        if (currentAnswers.includes(answer)) {
          newAnswers = currentAnswers.filter(a => a !== answer);
        } else {
          newAnswers = [...currentAnswers, answer];
        }
      } else {
        newAnswers = [answer];
      }

      const updatedAnswers = {
        ...prev,
        [currentQuestionIndex]: newAnswers
      };

      if (practiceMode) {
        const correctAnswersCount = exam.content.questions[currentQuestionIndex].answers.length;
        setShowFeedback(newAnswers.length === correctAnswersCount);
      }

      return updatedAnswers;
    });
    setError('');
  };

  const handleNext = () => {
    const currentAnswers = userAnswers[currentQuestionIndex] || [];
    const requiredAnswers = exam.content.questions[currentQuestionIndex].answers.length;
    
    if (currentAnswers.length !== requiredAnswers) {
      setError(`Please select ${requiredAnswers} answer${requiredAnswers > 1 ? 's' : ''} before proceeding.`);
      return;
    }
    setError('');
    setShowFeedback(false);
    if (currentQuestionIndex + 1 < exam.content.questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleFinish();
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setShowFeedback(false);
      setError('');
    }
  };

  const handleFinish = async () => {
    const endTime = Date.now();
    const timeTaken = Math.floor((endTime - startTime) / 1000);
    const score = calculateScore();
    const completedAttempt = {
      id: Date.now().toString(),
      score: score,
      answers: Object.values(userAnswers),
      completed: true,
      timeTaken: formatTime(timeTaken),
    };
    await updateExamScore(exam.name, completedAttempt);
    localStorage.removeItem('currentExam');

    navigate(`/exam-result/${encodeURIComponent(exam.name)}/${completedAttempt.id}`);
  };

  const handleExitConfirm = () => {
    localStorage.removeItem('currentExam');
    navigate('/account');
  };

  const handleExitCancel = () => {
    setShowExitDialog(false);
  };

  const calculateScore = () => {
    if (!exam) return 0;
    let score = 0;
    exam.content.questions.forEach((question, index) => {
      const userAnswer = userAnswers[index] || [];
      const correctAnswers = question.answers;
      if (JSON.stringify(userAnswer.sort()) === JSON.stringify(correctAnswers.sort())) {
        score++;
      }
    });
    return (score / exam.content.questions.length) * 100;
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const isAnswerCorrect = (option) => {
    const correctAnswers = exam.content.questions[currentQuestionIndex].answers;
    return correctAnswers.includes(option);
  };

  const isAllCorrect = () => {
    const userAnswer = userAnswers[currentQuestionIndex] || [];
    const correctAnswers = exam.content.questions[currentQuestionIndex].answers;
    return userAnswer.length === correctAnswers.length && 
           userAnswer.every(answer => correctAnswers.includes(answer));
  };

  if (!exam) return null;

  const currentQuestion = exam.content.questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex + 1 === exam.content.questions.length;
  const isMultipleChoice = currentQuestion.answers.length > 1;

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 p-4 flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-2xl"
      >
        <Card className="bg-white shadow-lg rounded-lg">
          <CardHeader className="flex justify-between items-center">
            <CardTitle className="text-xl font-semibold">
              Question {currentQuestionIndex + 1} of {exam.content.questions.length}
            </CardTitle>
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-600">Practice Mode</span>
              <Switch
                id="practice-mode"
                checked={practiceMode}
                onCheckedChange={() => {
                  setPracticeMode(!practiceMode);
                  setShowFeedback(false);
                }}
              />
            </div>
          </CardHeader>
          <CardContent>
            <p className="text-lg mb-4">{currentQuestion.question}</p>
            <p className="text-sm text-gray-600 mb-2">
              Select {isMultipleChoice ? currentQuestion.answers.length : 'one'} answer{isMultipleChoice ? 's' : ''}.
            </p>
            <div className="space-y-2">
              {currentQuestion.options.map((option, index) => (
                <label key={index} className="flex items-center space-x-2 cursor-pointer p-2 rounded">
                  <input
                    type={isMultipleChoice ? "checkbox" : "radio"}
                    name={`question-${currentQuestionIndex}`}
                    value={option}
                    checked={(userAnswers[currentQuestionIndex] || []).includes(option)}
                    onChange={() => handleAnswer(option)}
                    className={`form-${isMultipleChoice ? 'checkbox' : 'radio'} h-4 w-4 text-blue-600`}
                  />
                  <span className="text-md">{option}</span>
                  {practiceMode && showFeedback && (
                    isAnswerCorrect(option) ? (
                      <CheckSquare className="h-5 w-5 text-green-500 ml-auto" />
                    ) : (
                      (userAnswers[currentQuestionIndex] || []).includes(option) && (
                        <XSquare className="h-5 w-5 text-red-500 ml-auto" />
                      )
                    )
                  )}
                </label>
              ))}
            </div>
            {error && (
              <div className="mt-4 p-2 bg-red-100 text-red-700 rounded-md flex items-center">
                <AlertCircle className="h-5 w-5 mr-2" />
                <span>{error}</span>
              </div>
            )}
            {practiceMode && showFeedback && (
              <div className="mt-4 p-4 rounded-lg bg-blue-100">
                <p className="font-bold text-blue-700">
                  {isAllCorrect() ? "Correct!" : "Incorrect."}
                </p>
                <p className="text-gray-700 mt-2">{currentQuestion.explanation}</p>
                {currentQuestion.reference && (
                  <a
                    href={currentQuestion.reference}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-2 text-sm text-blue-600 hover:text-blue-800 flex items-center"
                  >
                    <ExternalLink size={16} className="mr-1" />
                    Learn more
                  </a>
                )}
              </div>
            )}
            <div className="flex justify-between mt-6">
              <Button onClick={handlePrevious} disabled={currentQuestionIndex === 0} variant="outline">
                Previous
              </Button>
              <Button onClick={() => setShowExitDialog(true)} variant="outline">
                Exit
              </Button>
              <Button 
                onClick={handleNext} 
                className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300"
                disabled={!userAnswers[currentQuestionIndex] || (practiceMode && !showFeedback)}
              >
                {isLastQuestion ? 'Finish' : 'Next'}
              </Button>
            </div>
          </CardContent>
        </Card>
      </motion.div>

      <AlertDialog isOpen={showExitDialog} onClose={() => setShowExitDialog(false)}>
        <AlertDialogHeader>Are you sure you want to exit?</AlertDialogHeader>
        <AlertDialogBody>
          Your progress will not be saved if you exit now. This action cannot be undone.
        </AlertDialogBody>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleExitCancel}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleExitConfirm}>Exit Exam</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialog>
    </div>
  );
};

export default PracticeTestPlayground;
