import React from 'react';

const Checkbox = ({ id, label, checked, onChange, className }) => {
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className={`form-checkbox h-4 w-4 text-lightblue-600 transition duration-150 ease-in-out ${className}`}
      />
      {label && (
        <label htmlFor={id} className="ml-2 block text-sm text-gray-900">
          {label}
        </label>
      )}
    </div>
  );
};

export { Checkbox };