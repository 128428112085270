import React, { useState, useContext } from 'react';
import { UserContext } from './UserContext';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Send, LogIn, AlertCircle, CreditCard, FolderOpen } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { motion, AnimatePresence } from 'framer-motion';

const API_ENDPOINT = 'https://api.practicelabs.ai/generate-test';

const LoadingDots = () => {
  return (
    <div className="flex space-x-2 justify-center items-center">
      <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"></div>
      <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
      <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
    </div>
  );
};

const LoginPrompt = ({ onLogin }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl w-full max-w-md">
        <CardContent className="p-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Login Required</h2>
          <p className="text-gray-600 mb-6">Please log in to generate practice exams and start learning.</p>
          <Button
            onClick={onLogin}
            className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
          >
            <LogIn className="mr-2 h-5 w-5" />
            Log In
          </Button>
        </CardContent>
      </Card>
    </motion.div>
  );
};

const ErrorModal = ({ message, onClose, isExamLimitError, onUpgrade, onManageExams }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl w-full max-w-md">
        <CardContent className="p-6">
          <div className="flex items-center mb-4">
            <AlertCircle className="h-6 w-6 text-red-500 mr-2" />
            <h2 className="text-2xl font-bold text-gray-800">Error</h2>
          </div>
          <p className="text-gray-600 mb-6">{message}</p>
          {isExamLimitError ? (
            <div className="space-y-4">
              <Button
                onClick={onUpgrade}
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
              >
                <CreditCard className="mr-2 h-5 w-5" />
                Upgrade Plan
              </Button>
              <Button
                onClick={onManageExams}
                className="w-full bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
              >
                <FolderOpen className="mr-2 h-5 w-5" />
                Manage Existing Exams
              </Button>
            </div>
          ) : (
            <Button
              onClick={onClose}
              className="w-full bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
            >
              Close
            </Button>
          )}
        </CardContent>
      </Card>
    </motion.div>
  );
};

const LLMInteraction = () => {
  const { user, addExam, updateExamScore } = useContext(UserContext);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isExamLimitError, setIsExamLimitError] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const navigate = useNavigate();

  const generateTest = async (prompt) => {
    if (!user) {
      setShowLoginPrompt(true);
      return;
    }

    setIsLoading(true);
    setError(null);
    setIsExamLimitError(false);

    try {
      const auth = getAuth();
      const idToken = await auth.currentUser.getIdToken(true);

      // Check if there's a matching exam in the user's saved exams
      const matchingExam = user.savedExams.find(exam =>
        exam.name.toLowerCase() === prompt.toLowerCase()
      );

      if (matchingExam) {
        // Start a new attempt on the existing exam
        const newAttempt = {
          id: Date.now().toString(),
          score: null,
          date: new Date().toISOString(),
          answers: [],
        };
        const updatedExam = {
          ...matchingExam,
          attempts: [...(matchingExam.attempts || []), newAttempt],
        };
        localStorage.setItem('currentExam', JSON.stringify(updatedExam));
        await updateExamScore(matchingExam.name, newAttempt);
        navigate('/practice-test');
      } else {
        // Check if the user has reached their exam limit
        if (user.plan === 'free' && user.savedExams.length >= 3) {
          setIsExamLimitError(true);
          throw new Error('Free users can only have up to 3 unique exams. Please upgrade your plan or manage your existing exams to create more.');
        }

        // Generate a new exam if no matching exam is found
        const response = await fetch(API_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${idToken}`,
          },
          body: JSON.stringify({ prompt: prompt }),
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.status}. Please try again later or contact support.`);
        }

        const data = await response.json();
        
        if (data.result) {
          const parsedResult = JSON.parse(data.result);
          if (parsedResult.questions && Array.isArray(parsedResult.questions) && parsedResult.questions.length > 0) {
            const newExam = { name: prompt, content: parsedResult, date: new Date().toISOString() };
            const addedExam = await addExam(newExam);
            localStorage.setItem('currentExam', JSON.stringify(addedExam));
            navigate('/practice-test');
          } else {
            throw new Error('Invalid test structure received. Please try again or choose a different topic.');
          }
        } else {
          throw new Error('Unexpected response from the exam generation service. Please try again later.');
        }
      }
    } catch (error) {
      console.error('Error generating test:', error);
      setError(error.message || 'An unknown error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      generateTest(input.trim());
    }
  };

  const handleTopicClick = (topic) => {
    setInput(topic);
    generateTest(topic);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleCloseError = () => {
    setError(null);
    setIsExamLimitError(false);
  };

  const handleUpgrade = () => {
    navigate('/pricing');
  };

  const handleManageExams = () => {
    navigate('/account');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex items-center justify-center">
      <div className="w-full max-w-3xl px-4">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl font-bold text-gray-800 text-center mb-4">AI Generated Practice Tests</h1>
          <p className="text-xl text-gray-600 text-center mb-8">Examine your skills in any topic or exam with the help of AI</p>
          
          <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
            <CardContent className="p-6">
              <form onSubmit={handleSubmit} className="mb-4">
                <div className="flex items-center space-x-2 bg-white rounded-full shadow-inner p-2">
                  <Input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Enter any subject, topic or exam"
                    className="flex-grow border-none shadow-none focus:ring-0"
                    disabled={isLoading}
                  />
                  <Button type="submit" className="bg-transparent hover:bg-gray-100 rounded-full p-2" disabled={isLoading}>
                    <Send className="h-5 w-5 text-gray-500" />
                  </Button>
                </div>
              </form>
              
              <div className="flex justify-center space-x-4">
                <Button onClick={() => handleTopicClick('AZ-900')} className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300">
                  AZ-900
                </Button>
                <Button onClick={() => handleTopicClick('AZ-104')} className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300">
                  AZ-104
                </Button>
                <Button onClick={() => handleTopicClick('AZ-305')} className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300">
                  AZ-305
                </Button>
              </div>

              {isLoading && (
                <div className="mt-4 text-center">
                  <p className="mb-2">Generating your practice exam</p>
                  <LoadingDots />
                </div>
              )}
            </CardContent>
          </Card>
        </motion.div>
      </div>
      <AnimatePresence>
        {showLoginPrompt && <LoginPrompt onLogin={handleLogin} />}
        {error && (
          <ErrorModal 
            message={error} 
            onClose={handleCloseError}
            isExamLimitError={isExamLimitError}
            onUpgrade={handleUpgrade}
            onManageExams={handleManageExams}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default LLMInteraction;
