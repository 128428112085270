import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { UserContext } from './UserContext';
import { motion } from 'framer-motion';
import { ExternalLink } from 'lucide-react';

const AttemptReview = () => {
  const navigate = useNavigate();
  const { examId, attemptId } = useParams();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [attempt, setAttempt] = useState(null);
  const [examContent, setExamContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchAttemptDetails = async () => {
      setIsLoading(true);
      setError(null);
      if (user && user.savedExams) {
        const exam = user.savedExams.find(exam => exam.name === decodeURIComponent(examId));
        if (exam) {
          const foundAttempt = exam.attempts.find(att => att.id === attemptId);
          if (foundAttempt) {
            setAttempt(foundAttempt);
            setExamContent(exam.content);
          } else {
            setError("Attempt not found");
          }
        } else {
          setError("Exam not found");
        }
      } else {
        setError("User data not available");
      }
      setIsLoading(false);
    };
  
    fetchAttemptDetails();
  }, [user, examId, attemptId]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 p-4 flex items-center justify-center">
        <Card className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg">
          <CardContent className="p-4">
            <p className="text-gray-600">Loading attempt details...</p>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (error || !attempt || !examContent) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 p-4 flex items-center justify-center">
        <Card className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg">
          <CardContent className="p-4">
            <p className="text-red-500">{error || "Failed to load attempt details"}</p>
            <Button onClick={() => navigate('/account')} className="mt-4 bg-blue-500 hover:bg-blue-600 text-white">
              Back to Account
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }


  const currentQuestion = examContent.questions[currentQuestionIndex];
  const userAnswer = attempt.answers[currentQuestionIndex] || '';
  const isMultipleChoice = Array.isArray(currentQuestion.answers) && currentQuestion.answers.length > 1;

  const handleNext = () => {
    if (currentQuestionIndex + 1 < examContent.questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 p-4 flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-2xl"
      >
        <Card className="bg-white shadow-lg rounded-lg">
          <CardHeader className="flex justify-between items-center">
            <CardTitle className="text-xl font-semibold">
              Question {currentQuestionIndex + 1} of {examContent.questions.length}
            </CardTitle>
            <Button onClick={() => navigate('/account')} variant="outline" className="text-blue-500 hover:bg-blue-50">
              Back to Account
            </Button>
          </CardHeader>
          <CardContent>
            <p className="text-lg mb-4">{currentQuestion.question}</p>
            <div className="space-y-2 mb-6">
              {currentQuestion.options.map((option, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <label className="flex items-center space-x-2 cursor-pointer w-full p-2 rounded">
                    <input
                      type={isMultipleChoice ? "checkbox" : "radio"}
                      name={`question-${currentQuestionIndex}`}
                      value={option}
                      checked={userAnswer.includes(option)}
                      readOnly
                      className={isMultipleChoice ? "form-checkbox h-4 w-4 text-blue-600" : "form-radio h-4 w-4 text-blue-600"}
                    />
                    <span className={`text-md ${
                      userAnswer.includes(option) 
                        ? (currentQuestion.answers.includes(option) ? "text-green-600 font-semibold" : "text-red-600")
                        : (currentQuestion.answers.includes(option) ? "text-green-600 font-semibold" : "")
                    }`}>
                      {option}
                    </span>
                  </label>
                </div>
              ))}
            </div>
            <div className="mt-4 p-4 bg-blue-50 rounded-lg">
              <p className="font-semibold text-blue-700">Correct answer(s): {currentQuestion.answers.join(', ')}</p>
              {currentQuestion.explanation && (
                <p className="mt-2 text-sm text-gray-600">Explanation: {currentQuestion.explanation}</p>
              )}
              {currentQuestion.reference && (
                <a
                  href={currentQuestion.reference}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-2 text-sm text-blue-600 hover:text-blue-800 flex items-center"
                >
                  <ExternalLink size={16} className="mr-1" />
                  Learn more
                </a>
              )}
            </div>
            <div className="flex justify-between mt-6">
              <Button onClick={handlePrevious} disabled={currentQuestionIndex === 0} variant="outline">
                Previous
              </Button>
              <Button 
                onClick={handleNext} 
                disabled={currentQuestionIndex + 1 === examContent.questions.length}
                className="bg-blue-500 hover:bg-blue-600 text-white"
              >
                {currentQuestionIndex + 1 === examContent.questions.length ? 'Finish' : 'Next'}
              </Button>
            </div>
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
};

export default AttemptReview;