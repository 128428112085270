import React from 'react';

export const AlertDialog = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '500px',
        width: '100%'
      }}>
        {children}
      </div>
    </div>
  );
};

export const AlertDialogHeader = ({ children }) => (
  <h2 style={{ marginTop: 0 }}>{children}</h2>
);

export const AlertDialogBody = ({ children }) => (
  <p>{children}</p>
);

export const AlertDialogFooter = ({ children }) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
    {children}
  </div>
);

export const AlertDialogCancel = ({ onClick, children }) => (
  <button 
    onClick={onClick}
    style={{
      padding: '8px 16px',
      marginRight: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      background: 'white',
      cursor: 'pointer',
    }}
  >
    {children}
  </button>
);

export const AlertDialogAction = ({ onClick, children }) => (
  <button 
    onClick={onClick}
    style={{
      padding: '8px 16px',
      border: 'none',
      borderRadius: '4px',
      background: '#007bff',
      color: 'white',
      cursor: 'pointer',
    }}
  >
    {children}
  </button>
);