import React, { useState, useEffect } from 'react';
import { X, Cookie, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const CookieConsentBanner = ({ onConsent }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsentGiven');
    if (!consentGiven) {
      setShowBanner(true);
    } else if (consentGiven === 'true') {
      onConsent(true);
    }
  }, [onConsent]);

  const handleAccept = () => {
    localStorage.setItem('cookieConsentGiven', 'true');
    setShowBanner(false);
    onConsent(true);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsentGiven', 'false');
    setShowBanner(false);
    onConsent(false);
  };

  return (
    <AnimatePresence>
      {showBanner && (
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="fixed bottom-4 left-4 right-4 z-50"
        >
          <div className="max-w-4xl mx-auto bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg shadow-lg overflow-hidden">
            <div className="p-6 flex flex-col sm:flex-row items-center justify-between">
              <div className="flex items-center mb-4 sm:mb-0">
                <Cookie className="h-8 w-8 text-white mr-4" />
                <p className="text-sm text-white">
                  We use cookies to enhance your learning experience. By continuing, you agree to our use of cookies and analytics.
                  <a href="/privacy" className="underline hover:text-blue-200 ml-1">Learn more</a>
                </p>
              </div>
              <div className="flex items-center">
                <button
                  onClick={handleAccept}
                  className="bg-white text-blue-600 hover:bg-blue-100 px-4 py-2 rounded-full font-semibold transition-colors duration-300 flex items-center"
                >
                  Accept
                  <ChevronRight className="h-4 w-4 ml-1" />
                </button>
                <button
                  onClick={handleDecline}
                  className="ml-2 bg-transparent hover:bg-white/10 text-white px-4 py-2 rounded-full font-semibold transition-colors duration-300"
                >
                  Decline
                </button>
                <button
                  onClick={() => setShowBanner(false)}
                  className="ml-2 bg-transparent hover:bg-white/10 text-white p-2 rounded-full transition-colors duration-300"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookieConsentBanner;