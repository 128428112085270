import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { UserContext } from './UserContext';
import { motion } from 'framer-motion';
import { Eye, EyeOff } from 'lucide-react';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { resetPassword } = useContext(UserContext);
  
  // Extract email and oobCode from URL parameters
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const oobCode = searchParams.get('oobCode');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await resetPassword(oobCode, newPassword);
      navigate('/login', { state: { message: 'Password reset successful. You can now log in with your new password.' } });
    } catch (error) {
      console.error('Password Reset Error:', error);
      setError(`Failed to reset password: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex flex-col items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <Card className="w-full bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
          <CardContent className="p-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Reset your password</h2>
            <p className="text-sm text-gray-600 mb-6">for {email}</p>
            <form onSubmit={handleResetPassword} className="space-y-6">
              <div className="relative">
                <Input 
                  type={showPassword ? "text" : "password"}
                  placeholder="New password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-3 pr-10 rounded-full border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
              {error && <p className="text-red-500 text-sm">{error}</p>}
              <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition-all duration-300" disabled={loading}>
                {loading ? 'Saving...' : 'SAVE'}
              </Button>
            </form>
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
};

export default ResetPassword;