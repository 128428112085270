import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Trophy, BarChart2, Clock, ArrowRight, FileText } from 'lucide-react';
import { UserContext } from './UserContext';

const ExamResultScreen = () => {
  const navigate = useNavigate();
  const { examId, attemptId } = useParams();
  const { user, updateExamScore } = useContext(UserContext);
  const [examResult, setExamResult] = useState(null);
  const [exam, setExam] = useState(null);

  useEffect(() => {
    if (user && user.savedExams) {
      const foundExam = user.savedExams.find(e => e.name === decodeURIComponent(examId));
      if (foundExam) {
        setExam(foundExam);
        const attempt = foundExam.attempts.find(a => a.id === attemptId);
        if (attempt) {
          setExamResult({
            examName: foundExam.name,
            score: attempt.score,
            totalQuestions: foundExam.content.questions.length,
            correctAnswers: Math.round(attempt.score * foundExam.content.questions.length / 100),
            timeTaken: attempt.timeTaken || "N/A"
          });
        }
      }
    }
  }, [user, examId, attemptId]);

  const handleReturnToAccount = () => {
    navigate('/account');
  };

  const handleRetakeExam = async () => {
    if (exam) {
      try {
        const newAttemptId = Date.now().toString();
        const newAttempt = {
          id: newAttemptId,
          score: null,
          date: new Date().toISOString(),
          answers: [],
          completed: false
        };

        // Update the exam with the new attempt
        const updatedExam = {
          ...exam,
          attempts: [...(exam.attempts || []), newAttempt]
        };

        // Update the exam score (which will add the new attempt)
        await updateExamScore(exam.name, newAttempt);

        // Store the current exam in localStorage
        localStorage.setItem('currentExam', JSON.stringify(updatedExam));

        // Navigate to the practice test
        navigate('/practice-test');
      } catch (error) {
        console.error("Error starting exam:", error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  const handleReviewAttempt = () => {
    navigate(`/account/${encodeURIComponent(examId)}/${attemptId}`);
  };

  if (!examResult) {
    return <div>Loading...</div>;
  }

  const correctAnswersPercentage = (examResult.correctAnswers / examResult.totalQuestions) * 100;

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 p-8 flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-3xl overflow-hidden">
          <CardHeader className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-6">
            <CardTitle className="text-2xl font-bold flex items-center justify-center">
              <Trophy className="mr-2 h-6 w-6" /> Exam Results
            </CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div className="text-center mb-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">{examResult.examName}</h2>
              <div className="text-5xl font-bold text-purple-600">{examResult.score.toFixed(1)}%</div>
            </div>
            
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div className="bg-blue-50 p-4 rounded-xl">
                <div className="flex items-center text-blue-600 mb-1">
                  <BarChart2 className="mr-2 h-5 w-5" />
                  <span className="font-semibold">Total Questions</span>
                </div>
                <div className="text-2xl font-bold text-gray-800">{examResult.totalQuestions}</div>
              </div>
              <div className="bg-purple-50 p-4 rounded-xl">
                <div className="flex items-center text-purple-600 mb-1">
                  <BarChart2 className="mr-2 h-5 w-5" />
                  <span className="font-semibold">Correct Answers</span>
                </div>
                <div className="text-2xl font-bold text-gray-800">{examResult.correctAnswers}</div>
              </div>
            </div>

            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-6">
              <div 
                className="bg-purple-600 h-2.5 rounded-full" 
                style={{ width: `${correctAnswersPercentage}%` }}
              ></div>
            </div>

            <div className="bg-green-50 p-4 rounded-xl mb-6">
              <div className="flex items-center text-green-600 mb-1">
                <Clock className="mr-2 h-5 w-5" />
                <span className="font-semibold">Time Taken</span>
              </div>
              <div className="text-2xl font-bold text-gray-800">{examResult.timeTaken}</div>
            </div>

            <div className="space-y-3">
              <Button 
                onClick={handleRetakeExam}
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300"
              >
                Retake Exam <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
              <Button 
                onClick={handleReviewAttempt}
                className="w-full bg-gradient-to-r from-green-500 to-teal-600 hover:from-green-600 hover:to-teal-700 text-white transition-all duration-300"
              >
                Review Attempt <FileText className="ml-2 h-4 w-4" />
              </Button>
              <Button 
                onClick={handleReturnToAccount}
                variant="outline"
                className="w-full border-purple-300 text-purple-600 hover:bg-purple-50 transition-all duration-300"
              >
                Return to Account
              </Button>
            </div>
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
};

export default ExamResultScreen;