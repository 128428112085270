import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { analytics } from './firebaseConfig';
import { setAnalyticsCollectionEnabled } from "firebase/analytics";
import LLMInteraction from './components/LLMInteraction';
import Login from './components/Login';
import SignUp from './components/SignUp';
// import Pricing from './components/Pricing';
// import Checkout from './components/Checkout';
// import ContactPage from './components/Contact';
import Account from './components/Account';
import ResetPassword from './components/ResetPassword';
import PracticeTestPlayground from './components/PracticeTestPlayground';
import ExamResultScreen from './components/ExamResultScreen';
import AttemptReview from './components/AttemptReview';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import TermsOfUsePage from './components/TermsOfUsePage';
import CookieConsentBanner from './components/CookieConsentBanner';
import './styles/globals.css';
import './styles/LLMInteraction.css';
import { UserProvider, UserContext } from './components/UserContext';
import { Menu, X, User, DollarSign, Home, LogIn, Phone } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Updated FloatingMenu component
const FloatingMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const menuItems = [
    { icon: Home, text: 'Home', path: '/' },
    { icon: user ? User : LogIn, text: user ? 'Account' : 'Login', path: user ? '/account' : '/login' },
    // { icon: DollarSign, text: 'Pricing', path: '/pricing' },
    // { icon: Phone, text: 'Contact', path: '/contact' },
  ];

  const menuVariants = {
    closed: { opacity: 0, scale: 0.8, transition: { duration: 0.2 } },
    open: { opacity: 1, scale: 1, transition: { duration: 0.2 } },
  };

  return (
    <div className="fixed top-4 right-4 z-50">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsOpen(!isOpen)}
        className="w-12 h-12 bg-gradient-to-r from-blue-500 to-purple-600 rounded-full shadow-lg flex items-center justify-center text-white transition-all duration-300"
      >
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </motion.button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
            className="absolute top-16 right-0 bg-white rounded-lg shadow-xl overflow-hidden"
          >
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className="flex items-center space-x-3 px-4 py-3 text-gray-700 hover:bg-gradient-to-r hover:from-blue-50 hover:to-purple-50 transition-all duration-300"
                onClick={() => setIsOpen(false)}
              >
                <item.icon size={18} className="text-blue-500" />
                <span>{item.text}</span>
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Main content component with routing
function AppContent() {
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false);

  const handleConsent = (consent) => {
    setAnalyticsEnabled(consent);
    setAnalyticsCollectionEnabled(analytics, consent);
    if (consent) {
      // Initialize or re-enable analytics here
      console.log("Analytics enabled");
    } else {
      console.log("Analytics disabled");
    }
  };

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsentGiven');
    if (consentGiven === 'true') {
      handleConsent(true);
    }
  }, []);

  return (
    <div className="App">
      <FloatingMenu />
      <CookieConsentBanner onConsent={handleConsent} />
      <Routes>
        <Route path="/" element={<LLMInteraction />} />
        <Route path="/login" element={<Login />} />
        {/*
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/contact" element={<ContactPage />} />
        */}
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/account" element={<Account />} />
        <Route path="/account/:examId/:attemptId" element={<AttemptReview />} />
        <Route path="/practice-test" element={<PracticeTestPlayground />} />
        <Route path="/exam-result/:examId/:attemptId" element={<ExamResultScreen />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsOfUsePage />} />
      </Routes>
    </div>
  );
}

// Main App component
function App() {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <UserProvider>
        <Router>
          <AppContent />
        </Router>
      </UserProvider>
    </GoogleOAuthProvider>
  );
}

export default App;